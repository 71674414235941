import amol from '../../media/Amol.jpg'
import alistair from '../../media/Alistair.jpg'
import ben from '../../media/Ben.jpg'
import mica from '../../media/Mica.jpg'

const Speakers = () => {
  return (
    <div className="max-[980px]:mx-[1.9375rem] mx-[8.75rem] lg:mt-[2.5rem] mt-[-3rem] small:mt-0">
      <h2 className="font-bold max-[439px]:text-[1.375rem] text-[2.5rem] text-black">
        Speakers
      </h2>
      <div className="max-[439px]:mt-[2rem] mt-[3rem]">
        <div>
          <div className="flex max-[730px]:flex-col max-[1265px]:items-center">
            <div className="flex-col">
              <div className="max-[439px]:w-[13.5rem] w-[18.0625rem] overflow-hidden rounded-[1.25rem]">
                <img src={amol} alt="Amol Verma" />
              </div>
              <p className="max-[439px]:mt-[0.25rem] mt-[0.5rem] font-bold max-[439px]:text-[1rem] text-[1.25rem] text-center">
                Amol Verma
              </p>
              <p className="max-[439px]:mt-[0.25rem] mt-[0.5rem] max-[439px]:text-[1rem] text-[1.25rem] text-center">
                Keynote Speaker
              </p>
            </div>
            <div>
              <p className="max-[730px]:ml-0 ml-[3rem] max-[439px]:mt-[1rem] max-[730px]:mt-[2rem] max-[439px]:text-[0.75rem]">
                Amol Verma is a physician and scientist at St. Michael’s Hospital, 
                Unity Health Toronto, and the Temerty Professor of AI Research and 
                Education in Medicine at the University of Toronto.
                <br />
                <br />
                Amol co-founded and co-leads GEMINI, Canada’s largest hospital clinical 
                data research network, which includes 30 hospitals in Ontario.
                <br />
                <br />
                He is a Provincial Clinical Lead for Quality Improvement in General 
                Internal Medicine with Ontario Health and Vice Chair of the Researcher 
                Council of the Digital Research Alliance of Canada.
              </p>
            </div>
          </div>

          <div className="flex max-[730px]:flex-col max-[439px]:mt-[4rem] max-[730px]:mt-[6rem] mt-[5.375rem] max-[1265px]:items-center">
            <div className="flex-col">
              <div className="max-[730px]:ml-0 ml-[0.5rem] max-[439px]:w-[12.5rem] w-[17.0625rem] overflow-hidden rounded-[1.25rem]">
                <img src={alistair} alt="Alistair Johnson" />
              </div>
              <p className="max-[730px]:ml-0 ml-[0.5rem] max-[439px]:mt-[0.25rem] mt-[0.5rem] font-bold max-[439px]:text-[1rem] text-[1.25rem] text-center">
                Alistair Johnson
              </p>
              <p className="max-[730px]:ml-0 ml-[0.5rem] max-[439px]:mt-[0.25rem] mt-[0.5rem] max-[439px]:text-[1rem] text-[1.25rem] text-center">
                Panelist Speaker
              </p>
            </div>
            <div>
              <p className="max-[730px]:ml-0 ml-[3.5rem] max-[439px]:mt-[1rem] max-[730px]:mt-[2rem] max-[439px]:text-[0.75rem]">
                Dr. Alistair Johnson is an independent scientist renowned for 
                his contributions to the field of healthcare data science, 
                particularly in the creation and sharing of large deidentified 
                electronic health record datasets such as MIMIC and eICU-CRD.
                <br />
                <br />
                His research focuses on applying machine learning and natural 
                language processing techniques.
                <br />
                <br />
                Dr. Johnson earned his DPhil in Healthcare Innovation from 
                the University of Oxford in 2014 and holds a BEng in 
                Biomedical & Electrical Engineering from McMaster University, 
                completed in 2009.
              </p>
            </div>
          </div>

          <div className="flex max-[730px]:flex-col max-[439px]:mt-[4rem] max-[730px]:mt-[6rem] mt-[5.375rem] max-[1265px]:items-center">
            <div className="flex-col">
              <div className="max-[730px]:ml-0 ml-[1.5rem] max-[439px]:w-[10.5rem] w-[15.0625rem] overflow-hidden rounded-[1.25rem]">
                <img src={ben} alt="Ben Fine" />
              </div>
              <p className="max-[730px]:ml-0 ml-[1.5rem] max-[439px]:mt-[0.25rem] mt-[0.5rem] font-bold max-[439px]:text-[1rem] text-[1.25rem] text-center">
                Ben Fine
              </p>
              <p className="max-[730px]:ml-0 ml-[1.5rem] max-[439px]:mt-[0.25rem] mt-[0.5rem] max-[439px]:text-[1rem] text-[1.25rem] text-center">
                Panelist Speaker
              </p>
            </div>
            <div>
              <p className="max-[730px]:ml-0 ml-[4.5rem] max-[439px]:mt-[1rem] max-[730px]:mt-[2rem] max-[439px]:text-[0.75rem]">
                Dr. Ben Fine is a Radiologist and Clinician Scientist at
                Trillium Health Partners, a University of Toronto-affiliated
                hospital system.
                <br />
                <br />
                He founded and leads the AI Deployment and Evaluation Lab at the
                Institute for Better Health and is the diagnostic imaging
                department lead for Quality and Informatics.
                <br />
                <br />
                Ben is an active startup advisor.
                <br />
                <br />
                He holds a Masters in Chemical Engineering from Massachusetts
                Institute of Technology and an MD from the University of
                Toronto.
              </p>
            </div>
          </div>

          <div className="flex max-[730px]:flex-col max-[439px]:mt-[4rem] max-[730px]:mt-[6rem] mt-[5.375rem] max-[1265px]:items-center mb-[1rem]">
            <div className="flex-col">
              <div className="max-[730px]:ml-0 ml-[1.5rem] max-[439px]:w-[10.5rem] w-[15.0625rem] overflow-hidden rounded-[1.25rem]">
                <img src={mica} alt="Mica Consens" />
              </div>
              <p className="max-[730px]:ml-0 ml-[1.5rem] max-[439px]:mt-[0.25rem] mt-[0.5rem] font-bold max-[439px]:text-[1rem] text-[1.25rem] text-center">
                Mica Consens
              </p>
              <p className="max-[730px]:ml-0 ml-[1.5rem] max-[439px]:mt-[0.25rem] mt-[0.5rem] max-[439px]:text-[1rem] text-[1.25rem] text-center">
                Panelist Speaker
              </p>
            </div>
            <div>
              <p className="max-[730px]:ml-0 ml-[4.5rem] max-[439px]:mt-[1rem] max-[730px]:mt-[2rem] max-[439px]:text-[0.75rem]">
                Mica Consens is a PhD candidate in computer science with a focus 
                on Machine Learning applications for healthcare at the University 
                of Toronto.
                <br />
                <br />
                She has worked in a variety of domains ranging from software 
                development at the Sick Kids Centre for Computational Medicine 
                and the UHN Techna Institute, to research for the CAMH Krembil 
                Centre for Neuroinformatics.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speakers;