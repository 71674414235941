
import Switch from '../../components/switch'
import { DAY_ONE, DAY_TWO } from './schedule'
import './style.css'

const Schedule = () => {

    return (
      <div
        className={
          "lg:mx-[8.75rem] ml-[1.9375rem] mr-[1.875rem] lg:mt-[2.5rem] mt-[-3rem] small:mt-0 mb-[2rem] "
        }
        id=""
      >
        <h1 className="small:text-[2.5rem] font-bold text-[1.375rem]">
          Event Schedule
        </h1>
        <p className=" font-[300] small:text-[1.125rem] text-[0.75rem] mt-[1.8125rem] small:mb-[5.25rem] mb-[3rem]">
          Keep track of the event schedule here!
        </p>
        <Switch dayOne={DAY_ONE} dayTwo={DAY_TWO} />
      </div>
    );

}

export default Schedule
