import './style.css';

import Card from '../../components/sponsorcards';

const Sponsors = () => {

    const Sponsors = ['google', 'tcairem', 'memotext', 'sansheng', 'fantuan', 'aavia'];

    return (
      <div className="flex flex-col lg:mt-[2.5rem] mt-[-3rem] small:mt-0">
        <div className="flex flex-col lg:mr-[8.4375rem] lg:ml-[8.75rem] ml-[1.9375rem] mr-[1.875rem] ">
          <h1 className="font-bold font-[poppins] text-[1.375rem] small:text-[2.5rem] text-[black] mb-[1.975rem]">
            Sponsors
          </h1>
          <p className="font-[poppins] text-[0.75rem] small:text-[1.125rem] text-black mb-[3.75rem] lg:mb-[4.375rem] font-[300] ">
            Thank you to this year’s sponsors! Their dedication to promoting AI
            health innovation through workshops, sponsor booths, panelist
            representation, mentorship, and participation prizes is greatly
            appreciated.
            <br></br>
            <br></br>
            Check back later for future sponsorship updates.
            <br></br>
          </p>

          <div className="flex flex-row justify-evenly flex-wrap gap-[0.5rem] lg:mb-[3.5rem] lg:gap-[4rem] mb-[2rem]">
            {Sponsors.map((sponsor, index) => {
              return <Card sponsor={sponsor} key={index} />;
            })}
          </div>

          <div className="lg:flex flex-col gap-[2rem] mb-[2.25rem] hidden">
            <p className="font-[poppins] font-bold text-[1.125rem]">
              Interested in becoming a sponsor?
            </p>
            <p className="font-[poppins] font-bold text-[1.125rem]">
              Contact our team at datathonhealth@gmail.com
            </p>
          </div>
        </div>

        <div className="flex flex-col">
          
          <div className="w-[100%] mb-[-0.25rem] h-[7.668375rem] small:h-[7.9145625rem] lg:h-[7.9375rem]  bg-cover bg-[url('./media/st-red.svg')] small:bg-[url('./media/mt-red.svg')] lg:bg-[url('./media/lt-red.svg')]">
            {/* // Top Red design */}
          </div>

          <div className="flex flex-col lg:gap-[4.375rem] gap-[1.875rem] bg-[#DF4440] z-10">
            <div className="flex lg:mt-[0.25rem] lg:flex-row lg:gap-[0rem] flex-col gap-[0.3125rem] xl:mt-[4rem]">
              <div className=" ml-[1.875rem] lg:mr-[1.78125rem] lg:ml-[8.75rem] lg:flex lg:justify-center lg:items-center">
                <p className="font-[poppins] font-bold small:text-[1.5625rem] lg:text-[2rem] text-white text-[1.25rem]">
                  What is our impact?
                </p>
              </div>
              <div className="flex-1 ml-[1.875rem] mr-[2.0625rem] lg:ml-[1.78125rem] lg:mr-[9.125rem]">
                <p className="max-[819px]:leading-[1.8] font-[poppins] font-[300] small:text-[1rem] lg:text-[1.125rem] text-white text-[0.75rem]">
                  We connect graduate students and professionals to{" "}
                  <span className="font-bold">
                    work together, build upon current skills, and learn new ones
                  </span>{" "}
                  that can be applied in future practices. Last year we had{" "}
                  <span className="font-bold">124</span>{" "}
                  attendees across a diverse set of program backgrounds, with{" "}
                  <span className="font-bold">79%</span> participating in a
                  datathon for the first time. Additionally,{" "}
                  <span className="font-bold">66%</span> of attendees made
                  connections with the panel that could lead to future
                  career-related opportunities, and{" "}
                  <span className="font-bold">100%</span> made meaningful
                  connections with other participants.
                </p>
              </div>
            </div>
            {/* <div className="flex lg:flex-row lg:gap-[0rem] flex-col gap-[0.6875rem] lg:mb-[2.8125rem]">
              <div className="ml-[1.875rem] lg:mr-[1.78125rem] lg:ml-[8.75rem] lg:flex lg:justify-center lg:items-center">
                <p className="font-[poppins] font-bold small:text-[1.5625rem] lg:text-[2rem] text-white text-[1.25rem] ">
                  Some other things?
                </p>
              </div>
              <div className="flex-1 ml-[1.875rem] mr-[2.0625rem] lg:ml-[1.78125rem] lg:mr-[9.125rem]">
                <p className="font-[poppins] font-[300] small:text-[1rem] lg:text-[1.125rem] text-white text-[0.75rem]">
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
            </div> */}
          </div>

          <div className="w-[100%] mt-[-0.25rem] h-[12.4375rem] small:h-[9.6498125rem] lg:h-[19.125rem] bg-cover bg-[url('./media/sb-red.svg')] small:bg-[url('./media/mb-red.svg')] lg:bg-[url('./media/lb-red.svg')]">
            {/* // Bottom Red design */}
          </div>
        
        </div>
      </div>
    );
};

export default Sponsors;