import google from "../../media/google.png";
import tcairem from "../../media/tcairem.png";
import sansheng from "../../media/sansheng.png";
import memo from "../../media/memo.png";
import aavia from "../../media/aavia.png";
import fantuan from "../../media/fantuan.png";

const Card = (props) => {
  switch (props.sponsor) {
    case "google":
      return (
        <div className=" lg:w-[13.5rem] lg:h-[5.3125rem] w-[9.75rem] h-[3.75rem] rounded-[0.625rem] justify-center items-center flex mb-4">
          <img src={google} alt="A logo of google" className="" />
        </div>
      );
    case "tcairem":
      return (
        <div className="lg:w-[13.5rem] lg:h-[5.3125rem] w-[9.75rem] h-[3.75rem] justify-center items-center flex mb-4">
          <img
            src={tcairem}
            className=" flex-1 border rounded-[0.625rem]"
            alt="A logo of tcairem"
          ></img>
        </div>
      );
    case "memotext":
      return (
        <div className="bg-[#FFC030] rounded-[0.625rem] lg:w-[13.5rem] lg:h-[5.3125rem] w-[9.75rem] h-[3.75rem] justify-center items-center flex mb-4 px-[3rem] ">
          <img
            src={memo}
            className=" flex-1 border rounded-[0.625rem] h-full border-none"
            alt="A logo of tcairem"
          ></img>
        </div>
      );
    case "sansheng":
      return (
        <div className="lg:w-[13.5rem] lg:h-[5.3125rem] w-[9.75rem] h-[3.75rem] justify-center items-center flex mb-4">
          <img
            src={sansheng}
            className=" flex-1 border h-full w-full border-none"
            alt="A logo of tcairem"
          ></img>
        </div>
      );
    case 'fantuan':
      return (
        <div className="lg:w-[13.5rem] lg:h-[5.3125rem] w-[9.75rem] h-[3.75rem] justify-center items-center flex mb-4 px-[0.5rem] py-[1rem]">
          <img
            src={fantuan}
            className=" flex-1 border h-full w-full border-none"
            alt="A logo of tcairem"
          ></img>
        </div>
      );
    case 'aavia':
      return (
        <div className="lg:w-[13.5rem] lg:h-[5.3125rem] w-[9.75rem] h-[3.75rem] justify-center items-center flex mb-4">
          <img
            src={aavia}
            className=" flex-1 border-none rounded-[0.625rem]"
            alt="A logo of tcairem"
          ></img>
        </div>
      );
    default:
      return (
        <div className="border lg:w-[13.5rem] lg:h-[5.3125rem] w-[9.75rem] h-[3.75rem] border-black border-solid rounded-[0.625rem] justify-center items-center flex mb-4">
          <h1 className="font-[poppins] text-[1.25rem] font-bold">Logo</h1>
        </div>
      );
  }
};

export default Card;

