export const DAY_ONE = [

    {
        title: 'Registration & Breakfast',
        startTime: '8:00 AM',
        endTime: '9:00 AM',
        description: 'Please ensure you arrive early.',
        speakers: []
    },
    {
        title: 'Opening Remarks',
        startTime: '9:00 AM',
        endTime: '9:30 AM',
        description: '',
        speakers: [
            {name: 'Eptehal Nashnoush', colour: '#AAE4E8'},
            {name: 'Muhammad Mamdani', colour: '#E8D2AA'}
        ]
    },
    {
        title: 'Keynote Speech',
        startTime: '9:30 AM',
        endTime: '10:15 AM',
        description: '',
        speakers: [
            {name: 'Amol Verma', colour: '#CCE8AA'}
        ]
    },
    {
        title: 'Datasets Overview',
        startTime: '10:15 AM',
        endTime: '10:45 AM',
        description: '',
        speakers: [
            {name: 'Eptehal Nashnoush', colour: '#AAE4E8'},
            {name: 'January Adams', colour: '#E8B5AA'}
        ]
    },
    {
        title: 'Coffee Break & Team Formation',
        startTime: '10:45 AM',
        endTime: '11:00 AM',
        description: '',
        speakers: []
    },
    {
        title: 'Group Work Session',
        startTime: '11:00 AM',
        endTime: '12:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Lunch',
        startTime: '12:00 PM',
        endTime: '1:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Group Work Session',
        startTime: '1:00 PM',
        endTime: '2:30 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Coffee Break',
        startTime: '2:30 PM',
        endTime: '2:45 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Group Work Session',
        startTime: '2:45 PM',
        endTime: '4:30 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Social Event',
        startTime: '6:30 PM',
        endTime: '9:00 PM',
        description: 'Panel event and networking. Dinner will be served!',
        description2: 'Location: 263 McCaul Street',
        speakers: [
            {name: 'Alistair Johnson', colour: '#AAB4E8'},
            {name: 'Ben Fine', colour: '#D7AAE8'},
            {name: 'Mica Consens', colour: '#E8E1AA'}
        ]
    },
]

 

export const DAY_TWO = [
    {
        title: 'Breakfast',
        startTime: '8:00 AM',
        endTime: '9:00 AM',
        description: '',
        speakers: []
    },
    {
        title: 'Group Work Session',
        startTime: '9:00 AM',
        endTime: '10:30 AM',
        description: '',
        speakers: []
    },
    {
        title: 'Coffee Break',
        startTime: '10:30 AM',
        endTime: '10:45 AM',
        description: '',
        speakers: []
    },
    {
        title: 'Group Work Session',
        startTime: '10:45 AM',
        endTime: '12:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Lunch',
        startTime: '12:00 PM',
        endTime: '1:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Group Work Session',
        startTime: '1:00 PM',
        endTime: '3:00 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Coffee Break',
        startTime: '3:00 PM',
        endTime: '3:30 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Team Presentations',
        startTime: '3:30 PM',
        endTime: '4:15 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Judging & Voting',
        startTime: '4:15 PM',
        endTime: '4:45 PM',
        description: '',
        speakers: []
    },
    {
        title: 'Closing Remarks & Prizes',
        startTime: '4:45 PM',
        endTime: '5:00 PM',
        description: '',
        speakers: [
            {name: 'Eptehal Nashnoush', colour: '#AAE4E8'},
        ]
    },
]