import './style.css';

const ScheduleItem = ({ scheduleItem }) => {


  const { title, startTime, endTime, description, description2, speakers } = scheduleItem;
  let height = ''
  let left = ''
  let left1 =''
 
  
  if (speakers.length > 0) {
    left =
      "  max-[330px]:!left-[6.5rem]";
    left1 =
      " max-[330px]:!left-[5.8rem]";
  }

   if (speakers.length > 0 && speakers[0].name === "Alistair Johnson") {
     height = " h-[10.75rem]";
     left =
       " max-[910px]:!left-[11rem] max-[510px]:!left-[8.5rem] max-[470px]:!left-[7.5rem] max-[330px]:!left-[6.5rem] max-small:!flex-col";
     left1 =
       " max-[910px]:!left-[10rem] max-[510px]:!left-[8rem] max-[470px]:!left-[6.9rem] max-[330px]:!left-[5.8rem]";
   }
  

    return (
      <div className={"schedule-item" + height}>
        <div className="left">
          <p className="schedule-time">
            {startTime} - {endTime}
          </p>
          <p className="schedule-title">{title}</p>
        </div>
        {(speakers.length > 0 || description !== "") && (
          <div className={"vertical-line" + left1}></div>
        )}
        <div
          className={"right " + left}
        >
          {speakers.length > 0 && (
            <div className="speakers-container max-small:!flex-col">
              {speakers.map((speaker, index) => (
                <div
                  key={index}
                  className="speaker-container"
                  style={{ background: speaker.colour }}
                >
                  <p className="speaker-name">{speaker.name}</p>
                </div>
              ))}
            </div>
          )}
          <div className="min-[1300px]:mt-[1rem] flex flex-col justify-center align-middle">
            {description !== "" && (
              <p className="schedule-description">
                {description.split(".")[0] + "."}
                {<br className="min-[1300px]:hidden"></br>}
                {description.split(".")[1] && description.split(".")[1]}
              </p>
            )}
            {description2 && (
              <p className="schedule-description">{description2}</p>
            )}
          </div>
        </div>
      </div>
    );
}

export default ScheduleItem