export const Leadership = [
  {
    name: "Eptehal Nashnoush",
    image: "eptehal_headshot.png",
    role: "Co-Chair & Founder",
  },
  { name: "Lance Madarang", image: "Lance.jpg", role: "Co-Chair" },
];

export const Marketing = [
    {name: 'Ipek Kocak', image: 'Ipek.jpg', role: 'Team Lead'},
    {name: 'Meredith Gladish', image: 'Meredith.jpg', role: 'Team Member'},
]

export const Operations = [
    {name: 'Mindy Somin Lee', image: 'SominMindyLee.jpg', role: 'Team Lead'},
    {name: 'Jae Park', image: 'jae park.jpg', role: 'Team Member'},
]

export const Sponsorship = [
    {name: 'Gianella Bejar Alvarez', image: 'IMG_7715 2.jpg', role: 'Team Lead'},
    {name: 'Zhi Ling Jiang', image: 'Zhi Ling.jpg', role: 'Team Member'},
]

export const Technology = [
    {name: 'Sanzhar Shamshiyev', image: 'Sanzhar.jpg', role: 'Team Lead'},
    {name: 'Angela Zhuo', image: 'Angela.jpg', role: 'Team Member'},
    {name: 'Dian Rong', image: 'Dian.jpg', role: 'Team Member'},
    {name: 'Jamie Yuan', image: 'Jamie.jpg', role: 'Team Member'},
    {name: 'Martina Bravo', image: 'martinabravo.jpg', role: 'Team Member'},
]