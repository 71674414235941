import './style.css';

const imagesPath = 'team_photos/';
const defaultImage = 'd9d9d9.png';

const TeamMember = ({name, image, role, hidden }) => {
    const imagePath = image !== '' ? imagesPath + image : imagesPath + defaultImage;
    const hiddenClass = hidden ? ' hidden' : '';
    const fade = hidden ? "fadeOut" : "fadeIn";

    return (
      <div
        className= {" fade-in " + hiddenClass}
      >
        <figure className="team-member">
          <div className="member-image-container">
            <img src={imagePath} className="member-image" alt={name}></img>
          </div>
          <figcaption>
            <h3 className="member-name">{name}</h3>
            <p className="member-role">{role}</p>
          </figcaption>
        </figure>
      </div>
    );
}

export default TeamMember