import './index.css';

const WinnersCard = () => {
  return (
    <div className="rounded-[1.5rem] bg-[url('./media/winners.png')] bg-[length:100%_100%] aspect-[2.25] flex">
    </div>
  );
}

const TeamCard = () => {
  return (
    <div className="rounded-[1.5rem] bg-[url('./media/team.jpg')] bg-[length:100%_100%] aspect-[2.25] flex">
    </div>
  );
}

const CommitteeCard = () => {
  return (
    <div className="rounded-[1.5rem] bg-[url('./media/committee.jpg')] bg-[length:100%_100%] aspect-[2.25] flex">
    </div>
  );
}

const Card = ({ category }) => {
  if (category === 'Winners') {
    return <WinnersCard category={category} />;
  }
  else if (category === 'Team') {
    return <TeamCard category={category} />;
  }
  else if (category === 'Committee') {
    return <CommitteeCard category={category} />;
  }
}
  
export default Card;