import {useEffect, useState} from "react";
import ScheduleItem from "../scheduleitem";
import './style.css'

const Tabs = (props) => {
    console.log(props)
    return (
      <div className={"flex flex-row gap-[0.375rem] mb-[-1px]"}>
        <div
          className={
            "h-[6.8125rem] max-[930px]:h-[5rem] max-[535px]:h-[6rem] bg-[#DF4440] flex  flex-col items-center flex-grow border-[#DF4440]" +
            " rounded-t-[0.625rem] "
          }
          onClick={() => props.onClick(1)}
          id="tab1"
        >
          <h3
            className={
              "text-[1.5rem] max-[930px]:text-[1rem]  text-white mt-[1.1875rem] max-small:text-[0.8rem]"
            }
          >
            Day One
          </h3>
          <p
            className={
              "text-[1.25rem] max-[930px]:text-[0.8rem] max-small:mx-[1.25rem] text-white text-center max-small:text-[0.75rem]"
            }
          >
            Thursday, February 22nd, 2023
          </p>
        </div>
        <div
          className={
            "h-[6.8125rem] max-[930px]:h-[5rem] max-[535px]:h-[6rem] bg-black flex-grow flex  flex-col items-center border-black rounded-t-[0.625rem]"
          }
          onClick={() => props.onClick(2)}
          id="tab2"
        >
          <h3
            className={
              "text-[1.5rem] max-[930px]:text-[1rem] text-white mt-[1.1875rem] max-small:text-[0.8rem]"
            }
          >
            Day Two
          </h3>
          <p
            className={
              "text-[1.25rem] max-[930px]:text-[0.8rem] max-small:mx-[1.25rem]  text-white text-center max-small:text-[0.75rem]"
            }
          >
            Friday, February 23nd, 2023
          </p>
        </div>
      </div>
    );
}


const Switch = ({dayOne, dayTwo}) => {
    const [bgcolor, setBGcolor] = useState('bg-[#DF4440]');
    const [scheduleItems, setScheduleItems] = useState([]);

    useEffect(() => {
      setScheduleItems(dayOne);
    }, [dayOne]);

    const change = (day) => {
        if (day === 2) {
            setBGcolor('bg-black');
            setScheduleItems(dayTwo);
        } else {
            setBGcolor('bg-[#DF4440]');
            setScheduleItems(dayOne);
          }
      }

    return (
        <div className={''}>
            <Tabs onClick={(day) => change(day)}/>
            <div className={'border-[#DF4440] rounded-b-[0.625rem] duration-500 transition-colors ' + bgcolor} id="schedule-container">
            {scheduleItems.map((scheduleItem, index) => (
              <ScheduleItem key={index} scheduleItem={scheduleItem}/>
            ))}
            </div>
        </div>
    )
}

export default Switch;
