import TeamMember from '../../components/TeamMember';
import './style.css';
import { Leadership, Marketing, Operations, Sponsorship, Technology } from './team_members';
import { useState } from 'react';

const Team = () => {

    const [team, setTeam] = useState('Leadership');
    const [leadershipId, setLeadershipId] = useState('selected-team');
    const [marketingId, setMarketingId] = useState('');
    const [operationsId, setOperationsId] = useState('');
    const [sponsorshipId, setSponsorshipId] = useState('');
    const [technologyId, setTechnologyId] = useState('');

    const handleTeamChange = (newTeam) => {
        setTeam(newTeam);
        setLeadershipId(newTeam === 'Leadership' ? 'selected-team' : '');
        setMarketingId(newTeam === 'Marketing' ? 'selected-team' : '');
        setOperationsId(newTeam === 'Operations' ? 'selected-team' : '');
        setSponsorshipId(newTeam === 'Sponsorship' ? 'selected-team' : '');
        setTechnologyId(newTeam === 'Technology' ? 'selected-team' : '');
    };

    const teamArrays = {
        'Leadership': Leadership,
        'Marketing': Marketing,
        'Operations': Operations,
        'Sponsorship': Sponsorship,
        'Technology': Technology,
    };
    const teams = ['Leadership', 'Marketing', 'Operations', 'Sponsorship', 'Technology']

    return (
      <>
        <div
          id="container"
          className="lg:mt-[1.25rem] small:mt-[-1.25rem] mt-[-4.25rem]"
        >
          <h1 id="team-title">Our Team</h1>
          <p id="team-intro">
            Meet our amazing team who works behind the scenes to make sure the
            Toronto Health Datathon is a success every year!
          </p>
          <div id="teams-container">
            <button
              onClick={() => handleTeamChange("Leadership")}
              id={leadershipId}
            >
              Leadership
            </button>
            <p>|</p>
            <button
              onClick={() => handleTeamChange("Marketing")}
              id={marketingId}
            >
              Marketing
            </button>
            <p>|</p>
            <button
              onClick={() => handleTeamChange("Operations")}
              id={operationsId}
            >
              Operations/Logistics
            </button>
            <p>|</p>
            <button
              onClick={() => handleTeamChange("Sponsorship")}
              id={sponsorshipId}
            >
              Sponsorship
            </button>
            <p>|</p>
            <button
              onClick={() => handleTeamChange("Technology")}
              id={technologyId}
            >
              Technology
            </button>
          </div>
          {teams.map((Team) => {

          
            return (
              <div
                id={"members-container"}
              >
                {teamArrays[Team].map((member, index) => {
                
                    return (
                      <TeamMember
                        key={index}
                        name={member.name}
                        image={member.image}
                        role={member.role}
                        hidden={team!==Team}
                      />
                    );
                  
                })}
              </div>
            );})
}


        </div>
      </>
    );
}

export default Team;