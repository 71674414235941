const Card = (props) => {
  let mb = props.title === "Promote" ? "mb-4" : "";
  mb = props.title === "Foster" ? "mb-5" : mb;
  return (
    <div>
      <div className="max-[450px]:w-[9.375rem] max-[610px]:w-[11rem] max-[1190px]:w-[12.625rem] max-[1300px]:w-[16.625rem] max-[1439px]:w-[18.625rem] w-[21.875rem] 
      max-[450px]:h-[9.375rem] max-[610px]:h-[11rem] max-[1190px]:h-[12.625rem] max-[1300px]:h-[16.625rem] max-[1439px]:h-[18.625rem] h-[20.5625rem] 
      bg-white rounded-[1.25rem]">
        <div className="flex flex-col max-[450px]:pt-[1.25rem] max-[610px]:[1.5rem] max-[1190px]:pt-[1.7rem] max-[1300px]:pt-[2.2rem] pt-[2.8rem] 
        max-[450px]:px-[1.25rem] max-[610px]:px-[1.5rem] max-[1190px]:px-[1.75rem] max-[1300px]:px-[2.5rem] px-[3rem] text-center">
          <p className="font-bold text-[#26394A] max-[450px]:text-[0.75rem] max-[610px]:text-[0.9rem] max-[1190px]:text-[1.1rem] max-[1300px]:text-[1.35rem] max-[1439px]:text-[1.5rem] text-[1.75rem]">
            {props.title}
            </p>
          <hr className={"self-center border-black rounded-full max-[450px]:w-[4.5rem] max-[610px]:w-[5.5rem] max-[1190px]:w-[6rem] max-[1300px]:w-[7rem] max-[1439px]:w-[8.5rem] w-[10rem] border-[0.09rem] max-[450px]:mt-[0.2rem] max-[610px]:mt-[0.3rem] max-[1190px]:mt-[0.5rem] max-[1300px]:mt-[0.6rem] mt-[0.75rem] " + mb}></hr>
          <p className="max-[450px]:text-[0.6rem] font-[400] max-[610px]:text-[0.7rem] max-[1190px]:text-[0.8rem] max-[1300px]:text-[1rem] max-[1439px]:text-[1.1rem] text-[1.375rem] max-[450px]:mt-[0.55rem] max-[610px]:mt-[0.7rem] max-[1190px]:mt-[0.9rem] mt-[1.25rem]">
            {props.contents}
            </p>
        </div>
      </div>
    </div>
  );
}

export default Card;